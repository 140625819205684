@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "custom";
@import "documaster-font";

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto'), url('fonts/Roboto-Regular.ttf') format('truetype');
}