html {
  width: 100%;
  height: 100%;
}
body {
  font-family: 'Roboto';
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 5em;
  padding: 1em;
  width: 100%;
}

header {
  .logo {
    max-height: 2.5em;
    padding: 0.2em;
    cursor: pointer;
    margin-right: 1em;
  }
  .product {
    font-size: 0.7em;
  }
  .logout {
    align-self: flex-end;
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: right;
  z-index: 200;
  height: 1.5em;
  line-height: 1.5em;
  p {
    font-size: 90%;
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
}

button {
  margin: 0.4em 0.2em 0.4em 0;
}

input[type="text"], input[type="password"], textarea {
  max-width: 20em;
  width: 20em;
}

.notifications-wrapper {
  //navbar ma 1030
  z-index: 1031;
  position: fixed;
  top: 0; right:0;

  .toast {
    position: sticky;
    top: 0;
    min-width: 15em;
  }
  .toast-body {
    background-color: white;
  }
  .toast-danger {
    color: red;
  }
}

.feedback-invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.state-ok {
  color: green;
}
.state-error {
  color: red ;
}
.state-warn {
  color: orangered;
}

.toner.cyan {
  color: #0ff;
}
.toner.magenta {
  color: #f0f;
}
.toner.yellow {
  color: yellow;
}
.toner.black {
  color: black;
}

.global {

  .pagination {
    flex-flow: row wrap;
    .page {
      max-width: 3.5em;
    }
  }

  .nav-link.active {
    text-decoration: underline;
  }

  .row {
    margin: 0;
  }

  .form-group, .form-row {
    margin-top: 1em;
  }

  h1, h2, h3 {
    margin-top: 2em;
  }
  .form-control-plaintext {
    outline: none;
  }
  textarea {
    max-width: 50em;
  }
}

table {
  &.table-hover {
    tbody {
      tr:hover {
        cursor: pointer;
      }
    }
  }
  td {
    &.filterable:hover {
      text-decoration: underline;
      font-style: italic;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40ch;
    white-space: nowrap;
    button {
      margin: 0 0.2em 0 0;
    }
  }
}

.search-input {
  .clear {
    display: flex;
    align-items: center;
    margin-left: 0.4em;
    font-size: 1.5em;
    &:hover {
      cursor: pointer;
    }
  }
  margin-bottom: 1em;
}
.status-info {
  span {
    margin-right: 0.2em;
  }
}

.notifications {
  .custom-checkbox {
    margin: 1em;
  }
}

.state {
  span+i {
    margin-left: 0.2em;
    font-size: $icon-size;
    vertical-align: middle;
  }
}

.toner-info {
  i {
    font-size: $icon-size;
  }
}

table.map {
  border-collapse: collapse;
  border-spacing: 0;
  td {
    padding-right: 1em;
  }
}

table.printer {
  table-layout: fixed;
  border-spacing: 0;

  td {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ordinal {
    width: 2em;
    text-align: right;
  }
  .nis {
    width: 4em;
  }
  .ip {
    width: 10em;
  }
  .serialNumber {
    width: 12em;
    max-width: 15em;
  }
  .mainCounter {
    width: 16em;
  }
  .location {
    width: 15em;
  }
  .tonerState {
    width: 10em;
    i { font-size: 120%; }
  }
  .stateSeverity {
    width: 15em;
    max-width: 35em;
  }
  .lastUpdate {
    width: 10em;
  }

  .name {
    width: 10em;
  }
}

table.location {
  .ordinal {
    width: 3em;
  }
  .name {
    width: 15em;
  }
  .path {
    width: 40em;
  }
  .actions {
    width: 20em;
  }
}

.query-fetch {
  font-size: 90%;
  padding-right: 1em;
}

.loader {
  i + span {
    margin-left: 0.5em;
  }
}

.oracle {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  .close {
    margin-left: 1em;
    height: 100%;
  }
  div.rbt {
    display: inline-block;
  }
}

//debug
//* {
//  outline: 1px solid #f00 !important;
//}