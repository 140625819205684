@font-face {
  font-family: 'Documaster';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/documaster-regular.ttf') format('truetype');
}

i.documaster {
  font-family: 'Documaster';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

i.ok:after { content: "\E1F6"; }
i.warn:after { content: "\E1F9"; }
i.call-service:after { content: "\E1A3"; }
i.toner:after { content: "\E1B0"; }
i.no-toner:after { content: "\E1B7"; }
i.paper:after { content: "\E1B8"; }
i.paper-jam:after { content: "\E1BE"; }
i.disconnected:after { content: "\E1F1"; }
i.door-open:after { content: "\E1C7"; }
