
body {
  background-color: black;
}

// Zmienne dla Bootstrapa
$body-bg: #fff;
$primary: #0c3a73;
$secondary: #e3eff7;
$font-family-sans-serif: arial;
$font-size-base: 0.8rem;

$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.1;
$h4-font-size: $font-size-base * 1.05;
$headings-font-weight: bold;
$headings-color: $primary;

// Zmiene używane w plikach Documaster-a
$frame-bg: linear-gradient(45deg, #002a6a, #3d90cc);
$frame-padding: 1em 1em 0em;

$icon-size: 1.3em;